

function GoFundMe(){
    return(
    <div className="container">
        <div className="flex" id="gofundmelink">
          <a href="https://gofund.me/bb246245" target="_blank" rel="noreferrer">My Dumb Dream</a>
        </div>
    </div> 
    );
}

export default GoFundMe;