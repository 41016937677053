
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { faGithub} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import ContactMe  from './components/ContactMe';
import { useState } from "react";




const Contact = () => {

    const [visible, setVisible] = useState(false);

    const handleToggle = () => {
        setVisible((current) => !current);
    }

    return(
    <div className="container">
        <div>
            <div className="PaddingShovePastHeader">
                <div className="ContactTitle"><span>Contact</span></div>
                <div className="flex ContactLinkSection">
                    <div className="x1 ContactIcon ">
                        <a href="https://www.linkedin.com/in/jack-ryan-3b8481262" target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                    <div className="x1 ContactIcon ">
                        <a href="https://github.com/ThisisJackRyan" target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                    </div>
                    <div className="x1 ContactIcon " >
                        <div onClick={handleToggle}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </div>
                </div>{
                <CSSTransition
                     in={!visible}
                     timeout={500}
                     appear={true}
                     classNames="transition"
                >
                    <ContactMe /> 
                </CSSTransition>
}               
            </div>
        </div>
       
    </div>
    );
}

export default Contact;